<template>
  <teleport to="head">
    <title>Home</title>
  </teleport>
  <div class="py-5 overflow-hidden">
    <div class="row px-1 px-lg-4">
      <div
        class="d-flex justify-content-center justify-content-lg-end align-items-lg-end user-count"
      >
        <div
          class="alert alert-primary alert-dismissible fade show sticky-top"
          role="alert"
        >
          <strong>Join 5.8M</strong> other users daily on Propelond worldwide.
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      </div>
      <div class="col-md-3 d-none d-lg-block">
        <Following v-if="isAuthenticated" />
        <div class="mt-4"></div>
      </div>
      <div class="col-lg-6 col-md-12">
        <NewsFeeds />
      </div>
      <div class="col-md-3 d-none d-lg-block">
        <Followers v-if="isAuthenticated" />
      </div>
    </div>
  </div>
</template>

<script>
import Followers from "@/components/Followers.vue"
import Following from "@/components/Following.vue"
import NewsFeeds from "@/components/NewsFeeds.vue"
import { isAuth, loadScript } from "@/utils/misc"
export default {
  name: "Home",
  components: {
    Followers,
    Following,
    NewsFeeds
  },
  data() {
    return {
      isAuthenticated: isAuth()
    }
  },
  mounted() {
    loadScript()
  }
}
</script>

<style scoped>
.home {
  height: fit-content;
}
.create-post-text {
  color: rgb(189, 188, 188);
  font-size: 0.8rem;
}
.option-btn {
  background-color: rgba(223, 223, 223, 0.801);
  border-radius: 100px;
  width: 2.5rem;
}
.pencil-icon {
  background-color: rgba(133, 122, 228, 0.24);
  border-radius: 100px;
}
/* create post box */
.profile-img {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 100px;
}
.create-post-box {
  border: 1px solid rgb(238, 238, 238);
  border-radius: 15px;
}
.input-box {
  border: none;
  outline: none;
}
/* browse news feed  */
.poster-name {
  font-weight: bold;
  margin-bottom: -0.1rem;
}
.post-time {
  font-size: 0.8rem;
  color: #c7ccd1;
}
.post_description {
  font-size: 1rem;
}
.post-image {
  width: 31rem;
}
.like-btn {
  outline: none;
  border: none;
  background-color: #fff;
}
.like-text {
  color: #a5a5a5;
}
/* .comment-box {
    border: 1px solid rgb(238, 238, 238);
    border-radius: 15px;
} */
.comment-box {
  background-color: #eeeeee;
}
.comment-input {
  background-color: #eeeeee;
  border: none;
  outline: none;
}
.user-count {
  margin-bottom: -4.5rem;
  z-index: 2;
}
</style>
