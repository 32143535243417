<template>
  <div>
    <div class="row">
      <div>
        <div class="follow-card shadow-sm">
          <div
            class="d-flex align-items-center justify-content-between py-2 px-3"
            style="border-bottom: 1px solid #f44336"
          >
            <h1 class="following-text mt-2">Followers</h1>
            <!-- <h1 class="edit-text text-primary">See All</h1> -->
            <div class="mb-0">
              <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M16.5 12A2.5 2.5 0 0 0 19 9.5 2.5 2.5 0 0 0 16.5 7 2.5 2.5 0 0 0 14 9.5a2.5 2.5 0 0 0 2.5 2.5M9 11a3 3 0 0 0 3-3 3 3 0 0 0-3-3 3 3 0 0 0-3 3 3 3 0 0 0 3 3m7.5 3c-1.83 0-5.5.92-5.5 2.75V19h11v-2.25c0-1.83-3.67-2.75-5.5-2.75M9 13c-2.33 0-7 1.17-7 3.5V19h7v-2.25c0-.85.33-2.34 2.37-3.47C10.5 13.1 9.66 13 9 13Z"
                />
              </svg>
            </div>
          </div>
          <div class="py-2">
            <!-- followers -->
            <div
              class="d-flex align-items-center justify-content-between px-2 mb-3"
              v-for="follower in followers"
              :key="follower.id"
            >
              <div class="d-flex align-items-center justify-content-center">
                <profile-image
                  :user="follower.user"
                  :size="63"
                  class="me-3"
                ></profile-image>
                <div class="ms-2">
                  <h5 class="follower-name">
                    {{ follower.user.first_name }}
                    {{ follower.user.last_name }}
                    <i
                      class="bi bi-patch-check-fill text-success ms-1"
                      v-if="follower.user.email_verified_at != null"
                    ></i>
                  </h5>
                  <h5 class="follower-user-name">
                    @{{ follower.user.first_name }}
                  </h5>
                  <!-- <p class="post-time">12 mutual friends</p> -->
                </div>
              </div>

              <button
                class="btn rounded-pill px-3 following-btn"
                @click="unfollow({ friend_id: follower.user_id })"
                v-if="follower.status == 1"
              >
                Unfollow
              </button>
              <button
                class="btn rounded-pill px-3 follow-btn px-4"
                @click="follow({ friend_id: follower.user_id })"
                v-else
              >
                Follow
              </button>
            </div>
            <div class="d-grid px-2 mt-2">
              <button class="btn show-btn">Show more</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import ProfileImage from "@/components/profile/ProfileImage.vue"
export default {
  components: {
    ProfileImage
  },
  mounted() {
    this.getFollowers()
  },
  computed: {
    ...mapState("follower", ["followers"])
  },
  methods: {
    ...mapActions("follower", ["getFollowers", "follow", "unfollow"])
  }
}
</script>

<style scoped>
.follow-card {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.following-text {
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.edit-text {
  font-size: 0.8rem;
}
.follower-name {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #000000;
}
.follower-user-name {
  margin-top: -0.5rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
}
.following-btn {
  font-weight: 600;
  font-size: 12px;
  color: #dadada;
  border: #ff411f solid 1px;
}
.follow-btn {
  background: linear-gradient(180deg, #ff411f 0%, #ff0bb9 100%);
  border-radius: 30px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  /* identical to box height */
  display: flex;
  align-items: center;

  color: #ffffff;
}
.show-btn {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #ff00d6;
}
</style>
