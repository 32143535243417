<template>
  <div>
    <div class="row">
      <div>
        <div class="follow-card shadow-sm">
          <div
            class="d-flex align-items-center justify-content-between py-2 px-3"
            style="border-bottom: 1px solid #f44336"
          >
            <h1 class="following-text mt-2">Search</h1>
            <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M10 13c-.35.59-.64 1.24-.81 1.93C6.5 15.16 3.9 16.42 3.9 17v1.1h5.3c.17.68.45 1.32.8 1.9H2v-3c0-2.66 5.33-4 8-4m0-9a4 4 0 0 1 4 4c0 .91-.31 1.75-.82 2.43-.86.32-1.63.83-2.27 1.47L10 12a4 4 0 0 1-4-4 4 4 0 0 1 4-4m0 1.9A2.1 2.1 0 0 0 7.9 8a2.1 2.1 0 0 0 2.1 2.1A2.1 2.1 0 0 0 12.1 8 2.1 2.1 0 0 0 10 5.9m5.5 6.1c2.5 0 4.5 2 4.5 4.5 0 .88-.25 1.71-.69 2.4l3.08 3.1L21 23.39l-3.12-3.07c-.69.43-1.51.68-2.38.68-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5m0 2a2.5 2.5 0 0 0-2.5 2.5 2.5 2.5 0 0 0 2.5 2.5 2.5 2.5 0 0 0 2.5-2.5 2.5 2.5 0 0 0-2.5-2.5Z"
              />
            </svg>
          </div>
          <div class="py-2">
            <div class="d-flex align-items-center justify-content-between p-3">
              <h1 class="following-text fw-bold">Following</h1>
              <div class="mb-3">
                <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M16.5 12A2.5 2.5 0 0 0 19 9.5 2.5 2.5 0 0 0 16.5 7 2.5 2.5 0 0 0 14 9.5a2.5 2.5 0 0 0 2.5 2.5M9 11a3 3 0 0 0 3-3 3 3 0 0 0-3-3 3 3 0 0 0-3 3 3 3 0 0 0 3 3m7.5 3c-1.83 0-5.5.92-5.5 2.75V19h11v-2.25c0-1.83-3.67-2.75-5.5-2.75M9 13c-2.33 0-7 1.17-7 3.5V19h7v-2.25c0-.85.33-2.34 2.37-3.47C10.5 13.1 9.66 13 9 13Z"
                  />
                </svg>
              </div>
            </div>

            <!-- following -->

            <div
              class="d-flex align-items-center justify-content-between px-2 mb-3"
              v-for="follow in following"
              :key="follow.id"
            >
              <div class="d-flex align-items-center justify-content-center">
                <profile-image
                  :user="follow.friend"
                  :size="63"
                  class="me-3"
                ></profile-image>
                <div class="ms-2">
                  <h5 class="follow-name">
                    {{ follow.friend.first_name }}
                    {{ follow.friend.last_name }}
                    <i
                      class="bi bi-patch-check-fill text-success ms-1"
                      v-if="follow.friend.email_verified_at != null"
                    ></i>
                  </h5>
                  <h5 class="follow-user-name">
                    @{{ follow.friend.first_name }}
                  </h5>
                </div>
              </div>
              <button
                class="btn rounded-pill px-3 following-btn"
                @click="unfollow({ friend_id: follow.friend_id })"
              >
                Unfollow
              </button>
            </div>
            <div class="d-grid px-2 mt-2">
              <button class="btn show-btn">Show more</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import ProfileImage from "@/components/profile/ProfileImage.vue"
export default {
  components: {
    ProfileImage
  },
  mounted() {
    this.getFollowing()
  },
  computed: {
    ...mapState("follower", ["following"])
  },
  methods: {
    ...mapActions("follower", ["getFollowing", "unfollow"])
  }
}
</script>

<style scoped>
.follow-card {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.following-text {
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.edit-text {
  font-size: 0.8rem;
}
.follow-name {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #000000;
}
.follow-user-name {
  margin-top: -0.5rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
}
.following-btn {
  font-weight: 600;
  font-size: 12px;
  color: #dadada;
  border: #ff411f solid 1px;
}
.show-btn {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #ff00d6;
}
</style>
