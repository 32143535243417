<template>
  <div class="px-2 mx-md-6 mx-lg-2">
    <div class="rounded-3 bg-white p-3 shadow-sm">
      <div class="d-flex align-items-center">
        <div
          class="d-flex align-content-center align-items-center px-2 py-2 pencil-icon rounded-circle"
        >
          <svg
            style="width: 24px; height: 24px; color: #ff411f"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="m14.06 9 .94.94L5.92 19H5v-.92L14.06 9m3.6-6c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29m-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75Z"
            />
          </svg>
        </div>
        <span class="mx-1 create-post-text">Create Post</span>
      </div>
      <post-modal
        :user="user"
        :post="editedPost"
        :method="postMethod"
        @reset-default="closePostModal"
        ref="postModal"
      ></post-modal>

      <login-modal ref="loginModal"></login-modal>
      <!-- create post box -->
      <div
        class="d-flex align-items-center px-2 mt-3 py-1 rounded-pill"
        style="border: 1px solid #ff411f"
      >
        <profile-image
          :user="user"
          :size="40"
          :is-link="false"
          class="me-3"
        ></profile-image>
        <button
          type="button"
          class="btn btn-compose ms-2 rounded-pill w-100 text-left"
          style="text-align: left"
          @click="openPostModal"
        >
          Compose <span v-if="user">, {{ user.first_name }}</span> ?
        </button>
      </div>
    </div>

    <!-- browse news feed -->
    <div class="mt-2" v-if="posts">
      <div
        class="rounded-3 bg-white shadow-sm mt-3"
        v-for="post in posts"
        :key="post.id"
      >
        <post-detail :post="post" :user="user"></post-detail>
      </div>
      <button
        type="button"
        class="btn btn-outline-primary my-4"
        @click="loadNextPage"
        v-if="page.last_page > page.current_page"
      >
        See more posts
        <i class="bi bi-arrow-up-right-square-fill"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { isAuth } from "@/utils/misc"
import { Modal } from "bootstrap"
import PostModal from "@/components/post/PostModal.vue"
import LoginModal from "@/components/LoginModal.vue"
import PostDetail from "@/components/post/PostDetail.vue"
import ProfileImage from "@/components/profile/ProfileImage.vue"

export default {
  name: "NewsFeeds",
  components: { PostModal, LoginModal, PostDetail, ProfileImage },
  data() {
    const editedPost = {
      description: "",
      image_path: [],
      video_path: []
    }

    return {
      postMethod: "create",
      editedPost
    }
  },
  async mounted() {
    if (isAuth()) {
      await this.$store.dispatch("user/getCurrentUser")
    }
    if (this.$route.path.includes("profile")) {
      this.getUserPosts({ page: 1, userId: this.$route.params.id })
    } else {
      this.getPosts({ page: 1 })
    }
  },
  computed: {
    ...mapState("post", ["posts", "page"]),
    ...mapState("user", ["user", "profile"])
  },
  methods: {
    ...mapActions("post", [
      "getPosts",
      "createPost",
      "editPost",
      "deletePost",
      "getUserPosts"
    ]),
    closePostModal() {
      this.editedPost = {
        description: "",
        image_path: [],
        video_path: []
      }
    },
    openLoginModal() {
      const modal = new Modal(this.$refs.loginModal.$refs.loginModal)
      modal.show()
    },
    openPostModal() {
      if (!isAuth()) {
        this.openLoginModal()
        return
      }

      const modal = new Modal(this.$refs.postModal.$refs.postModal)
      modal.show()
    },
    loadNextPage() {
      if (this.$route.path.includes("profile")) {
        this.getUserPosts({
          page: this.page.current_page + 1,
          userId: this.$route.params.id
        })
      } else {
        this.getPosts({ page: this.page.current_page + 1 })
      }
    }
  }
}
</script>

<style scoped>
.create-post-text {
  color: rgb(189, 188, 188);
  font-size: 0.8rem;
}

.pencil-icon {
  background-color: #f5f5f5;
}
.btn-compose {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #cdcdcd;
}
</style>
